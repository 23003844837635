import ApiService from "./ApiService";

// gets donor profiles
export async function apiGetAllDonors(data) {
  return ApiService.fetchData({
    url: `/donors/${data.client_id}/forms`,
    method: "get",
    data,
  });
}

export async function apiGetDonorCard(data) {
  return ApiService.fetchData({
    url: `/admin/donor-credit-card/${data.donor_id}`,
    method: "get",
    data,
  });
}

//refund the donation
export async function apiRefundDonation(data) {
  return ApiService.fetchData({
    url: `/admin/donation/${data}/refund`,
    method: "get",
    data,
  });
}

export async function apiGetPayPalStatus(data) {
  return ApiService.fetchData({
    url: `/donor/paypal`,
    method: "get",
    data,
  });
}

export async function apiUpdateDonorCard(params, data) {
  return ApiService.fetchData({
    url: `/admin/donor-credit-card/${params.donor_id}`,
    method: "post",
    data,
  });
}

export async function apiDeleteDonorCard(data){
  return ApiService.fetchData({
    url: `/admin/ddonor-credit-card/${data}`,
    method: "delete",
  });
}

export async function apiUpdateDonorPayPal(data) {
  return ApiService.fetchData({
    url: `/donor/${data.donor_id}/paypal`,
    method: "post",
    data,
  });
}

export async function apiGetUploadData(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/uploads/${params.client_id}/${params.program_id}/${params.entity_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apiGetAddDonorData(data) {
  return ApiService.fetchData({
    url: `/admin/donor/${data.hysform_id}`,
    method: "get",
  });
}

export async function apiAddDonor(data) {
  return ApiService.fetchData({
    url: `/admin/donor/${data.hysform_id}`,
    method: "post",
    data,
  });
}

export async function apiGetDonorStatistics(data) {
  return ApiService.fetchData({
    url: `/admin/statistics/donors`,
    method: "get",
    data,
  });
}

export async function apiGetAllDonorsTableHeaders(data) {
  return ApiService.fetchData({
    url: `/admin/donors/${data.hysform_id}/headers`,
    method: "get",
    data,
  });
}

// gets all donors
export async function apiGetAllDonorsData(params) {
  return ApiService.fetchData({
    url: `/admin/all_donors`,
    method: "get",
    params,
  });
}

// gets all donors for a donor profile
export async function apiGetAllDonorsTableData(params) {
  return ApiService.fetchData({
    url: `/admin/donors/${params.hysform_id}`,
    method: "get",
    params,
  });
}

export async function apiArchiveDonor(data) {
  return ApiService.fetchData({
    url: "/admin/donors/archive",
    method: "post",
    data,
  });
}

export async function apiGetDonorFieldOptions(data) {
  return ApiService.fetchData({
    url: `/admin/donors/${data.hysform_id}/fields/${data.type}`,
    method: "get",
    data,
  });
}

export async function apiPostDonorFieldOptions(data) {
  return ApiService.fetchData({
    url: "/admin/donors/field_options",
    method: "post",
    data,
  });
}

export async function apiDeleteSavedReport(data) {
  return ApiService.fetchData({
    url: `/admin/donor/view/${data.report_id}/${data.hysform_id}`,
    method: "delete",
    data,
  });
}

export async function apiGetSavedReportColumn(params, data) {
  return ApiService.fetchData({
    url: `/admin/donor/view/${params.report_id}/${params.hysform_id}`,
    method: "post",
    data,
  });
}

export async function apiGetEditDonor(data) {
  return ApiService.fetchData({
    url: `/donor/${data.donor_id}`,
    method: "get",
    data,
  });
}

export async function apiUpdateDonor(data) {
  return ApiService.fetchData({
    url: `/donor/${data.donor_id}/edit`,
    method: "patch",
    data,
  });
}

export async function apiGetDonorSponsorship(data) {
  return ApiService.fetchData({
    url: `/admin/donor/${data.id}/sponsorships`,
    method: "get",
  });
}

export async function apiPostDonorSponsorship(data) {
  return ApiService.fetchData({
    url: "/admin/sponsorships/donor",
    method: "post",
    data,
  });
}

export async function apiPostSponsorship(data) {
  return ApiService.fetchData({
    url: "/admin/sponsorships",
    method: "post",
    data,
  });
}

export async function apiDeleteSponsorship(data) {
  return ApiService.fetchData({
    url: `/admin/sponsorships/${data.donor_entity_id}/archive`,
    method: "post",
  });
}

export async function apiDeleteSponsorships(data) {
  return ApiService.fetchData({
    url: `/admin/sponsorships/archive`,
    method: "post",
    data,
  });
}

export async function apiRestoreSponsorship(data) {
  return ApiService.fetchData({
    url: `/admin/sponsorships/${data.donor_entity_id}/restore`,
    method: "post",
  });
}

export async function apiGetDonorCommitment(data) {
  return ApiService.fetchData({
    url: `/admin/commitment/${data.commitment_id}/donation`,
    method: "get",
    data,
  });
}

export async function apiPutCommitment(data) {
  return ApiService.fetchData({
    url: `/admin/commitment/${data.commitment_id}/donation`,
    method: "post",
    data,
  });
}

export async function apiGetEditCommitment(data) {
  return ApiService.fetchData({
    url: `/admin/commitments/${data.commitment_id}`,
    method: "get",
  });
}

export async function apiDeleteCommitment(data) {
  return ApiService.fetchData({
    url: `/admin/donor/${data.commitment_id}/email`,
    method: "get",
  });
}

export async function apiNotifyDonor(data) {
  return ApiService.fetchData({
    url: "/admin/donors/notify",
    method: "post",
    data,
  });
}

export async function apiPostSendYearEmail(data) {
  return ApiService.fetchData({
    url: "/admin/donors/send_year_end",
    method: "post",
    data,
  });
}

export async function apiRestoreDonor(data) {
  return ApiService.fetchData({
    url: "/admin/donors/activate",
    method: "post",
    data,
  });
}

export async function apiUpdateCommitement(data) {
  return ApiService.fetchData({
    url: `/admin/commitments/${data.commitment_id}`,
    method: "put",
    data,
  });
}

export async function apiUpdateCommitements(data) {
  return ApiService.fetchData({
    url: `/admin/commitments`,
    method: "patch",
    data,
  });
}

export async function apiDeleteDonor(data) {
  return ApiService.fetchData({
    url: "/admin/delete/donors",
    method: "delete",
    data,
  });
}

export async function apiGetAllAvailableDonors() {
  return ApiService.fetchData({
    url: "/admin/donors",
    method: "get",
  });
}
