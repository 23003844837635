import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetEditDonor,
  apiGetDonorCard,
  apiGetPayPalStatus,
  apiUpdateDonorCard,
  apiDeleteDonorCard,
  apiUpdateDonorPayPal,
  apiGetDonorSponsorship,
  apiRefundDonation,
} from "services/DonorService";
import {
  apiGetAccountSettingData,
  apiPutUpdateCard,
} from "services/AccountServices";
import { apigetDonorUpdateCard } from "services/FrontendServices";
import { apiDonationsByDonor } from "services/DonationService";
import {
  apiGetNotes,
  apiGetNote,
  apiUpdateNote,
  apiGetDonorActivityLogs
} from "services/ProgramsService";

export const getDonorDetails = createAsyncThunk(
  "getDonorDetails/data/getDonorDetails",
  async (data) => {
    const response = await apiGetEditDonor(data);
    return response.data;
  }
);

export const getDonationsByDonor = createAsyncThunk(
  "dontaionByDonor/data/getDonationsByDonor",
  async (data) => {
    const response = await apiDonationsByDonor(data);
    return response.data;
  }
);

export const getSponsorships = createAsyncThunk(
  "getDonorDetails/data/getArchvedSponsorships",
  async (data) => {
    const response = await apiGetDonorSponsorship(data);
    return response.data;
  }
);

export const getAccountSettings = createAsyncThunk(
  "settingsList/data/getAccountSettings",
  async (data) => {
    const response = await apiGetAccountSettingData(data);
    return response.data;
  }
);

export const getDonorCard = createAsyncThunk(
  "getDonorDetails/data/getDonorCard",
  async (data) => {
    const response = await apiGetDonorCard(data);
    return response.data;
  }
);

export const getPayPalStatus = createAsyncThunk(
  "getDonorDetails/data/getPayPalStatus",
  async (data) => {
    const response = await apiGetPayPalStatus(data);
    return response.data;
  }
);

//refund the payment made to the client from the donor
export const refundDonation = async (data) => {
  const response = await apiRefundDonation(data);
  return response.data;
};

export const updateCard = async (data) => {
  const response = await apiPutUpdateCard(data);
  return response.data;
};

export const updateDonorCard = async (params, data) => {
  const response = await apiUpdateDonorCard(params, data);
  return response.data;
};

export const deleteDonorCard = async (data) => {
  const response = await apiDeleteDonorCard(data);
  return response.data;
};

export const updateDonorPayPal = async (data) => {
  const response = await apiUpdateDonorPayPal(data);
  return response.data;
};

export const getDonorUpdateCard = createAsyncThunk(
  "getDonorDetails/data/getDonorUpdateCard",
  async (data) => {
    const response = await apigetDonorUpdateCard(data);
    return response.data;
  }
);

export const getNotes = createAsyncThunk(
  "getDonorDetails/data/getNotes",
  async (data) => {
    const response = await apiGetNotes(data);
    return response.data;
  }
);

export const getNote = createAsyncThunk(
  "getDonorDetails/data/getNote",
  async (data) => {
    const response = await apiGetNote(data);
    return response.data;
  }
);

export const updateNote = async (data) => {
  const response = await apiUpdateNote(data);
  return response.data;
};

export const getDonorActivityLogs = createAsyncThunk(
  "getDonorDetails/data/getActivityLogs",
  async (data) => {
    const response = await apiGetDonorActivityLogs(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  // query: "",
  // sortOrder: "",
  // sortKey: ""
};

const dataSlice = createSlice({
  name: "getDonorDetails/data",
  initialState: {
    loading: true,
    cardloading: false,
    notesLoading: false,
    noteLoading: false,
    donationsLoading: false,
    archivedLoading: false,
    donorUpdateCardLoading: false,
    donorUpdateCardData: {},
    productList: {},
    cardList: {},
    donorData: [],
    donorCards: [],
    donations: [],
    sponsorships: [],
    notesList: [],
    noteData: [],
    dataLength: [],
    tableData: initialTableData,
    error: null,
    donorActivityLogs:[],
    donorActivityLogsLoading:false
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    updateProductList: (state, action) => {
      state.productList = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    updatePaymentMethodData: (state, action) => {
      state.paymentMethodData = action.payload;
    },
  },
  extraReducers: {
    [getDonorDetails.fulfilled]: (state, action) => {
      state.donorData = action.payload;
      state.loading = false;
    },
    [getDonorDetails.pending]: (state) => {
      state.loading = true;
    },
    [getDonorCard.fulfilled]: (state, action) => {
      state.donorCards = action.payload;
      state.cardloading = false;
    },
    [getDonorCard.pending]: (state) => {
      state.cardloading = true;
    },
    [getPayPalStatus.fulfilled]: (state, action) => {
      state.paypalStatus = action.payload;
      state.cardloading = false;
    },
    [getPayPalStatus.pending]: (state) => {
      state.cardloading = true;
    },
    [getDonationsByDonor.fulfilled]: (state, action) => {
      state.donations = action.payload;
      state.donationsLoading = false;
    },
    [getDonationsByDonor.pending]: (state) => {
      state.donationsLoading = true;
    },
    [getAccountSettings.fulfilled]: (state, action) => {
      state.productList = action.payload;
      state.loading = false;
    },
    [getAccountSettings.pending]: (state) => {
      state.loading = true;
    },
    [getDonorUpdateCard.pending]: (state) => {
      state.donorUpdateCardLoading = true;
    },
    [getDonorUpdateCard.fulfilled]: (state, action) => {
      state.donorUpdateCardLoading = false;
      state.donorUpdateCardData = action.payload;
    },
    [getNotes.fulfilled]: (state, action) => {
      state.notesList = action.payload;
      state.notesLoading = false;
    },
    [getNotes.pending]: (state) => {
      state.notesLoading = true;
    },
    [getNote.fulfilled]: (state, action) => {
      state.noteData = action.payload;
      state.noteLoading = false;
    },
    [getNote.pending]: (state) => {
      state.noteLoading = true;
    },
    [getSponsorships.fulfilled]: (state, action) => {
      state.sponsorships = action.payload;
      state.archivedLoading = false;
    },
    [getSponsorships.pending]: (state, action) => {
      state.archivedLoading = true;
    },
    [getDonorDetails.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getDonorActivityLogs.fulfilled]: (state, action) => {
      state.donorActivityLogs = action.payload;
      state.donorActivityLogsLoading = false;
    },
    [getDonorActivityLogs.pending]: (state, action) => {
      state.donorActivityLogsLoading = true;
    },
  },
});

export const {
  setTableData,
  updateProductList,
  setFilterData,
  setSortedColumn,
} = dataSlice.actions;

export default dataSlice.reducer;
