import React, { useCallback, useState} from "react";
import { AdaptableCard, ActionLink } from "components/shared";
import { FormItem, Select, Button, toast, Notification, Input, Dialog } from "components/ui";
import { Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { sendEmail } from "../ProgramsList/store/dataSlice";
import { openMessageManagerDialog, closeMessageManagerDialog } from "../ProgramsList/store/stateSlice";
import RecipientMessageNew from "../RecipientMessageNew";
import {
  renderHysDateField,
  renderHysTextareaField,
  renderHysSelectField,
  renderHysStaticField,
  renderHysCheckboxField,
  renderHysTextField,
} from "./formHelpers";

const RecipientFields = (props) => {
  const NUMBER_PROGRAM_TYPE = "number";
  const CAMPAIGN_PROGRAM_TYPE = "campaign";
  const CONTRIBUTION_PROGRAM_TYPE = "contribution";
  const { client_id } = useSelector((state) => state.auth.user);
  const { permissions } = useSelector((state) => state.auth.session);
  const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false);

  const { values, handleChange, errors, touched } = props;

  const dispatch = useDispatch();

  const openDialog = useCallback((e) => {
    e.preventDefault();
    dispatch(openMessageManagerDialog())
  }, [dispatch]);

  const onDialogClose = useCallback(() => {
    dispatch(closeMessageManagerDialog())
  }, [dispatch]);

  const fields = useSelector(
    (state) => state.entitiesList.data?.fieldsList?.fields
  );

  const messageManagerDialog = useSelector(
    (state) => state.entitiesList.state.messageManagerDialog
  )

  const hysformFields = useSelector(
    (state) => state.entitiesEdit.data.entityData?.fields
  );

  const programType = useSelector(
    (state) => state.entitiesEdit.data.entityData?.programData.type
  );

  const donation_target = useSelector((state) => {
    if (programType === CAMPAIGN_PROGRAM_TYPE) {
      return state.entitiesEdit.data.entityData?.programData?.sp_num[0]?.amount;
    }
    return null;
  });

  const filteredArray = Array.isArray(fields)
    ? hysformFields
        .filter((item) => {
          return fields?.some((mainItem) => {
            return (
              mainItem.hysform_id === item.hysform_id &&
              mainItem.field_key === item.field_key
            );
          });
        })
        .map((item) => {
          const mainItem = fields?.find((mainItem) => {
            return (
              mainItem.hysform_id === item.hysform_id &&
              mainItem.field_key === item.field_key
            );
          });
          return { ...item, field_data: mainItem.field_data };
        })
    : [];

  const sortedRecipientFields = [...filteredArray].sort(
    (a, b) =>
      filteredArray.indexOf(a.field_key) - filteredArray.indexOf(b.field_key)
  );

  const sponsors = useSelector(
    (state) => state.entitiesEdit.data.entityData?.sponsors
  );

  const sponsorsName = sponsors[0]?.name;
  const sponsorId = sponsors[0]?.id;

  const numberOfSponsorsField = useSelector(
    (state) => state.entitiesEdit.data.entityData?.programData?.number_sponsors
  );

  const sponsorshipNumberOptions = numberOfSponsorsField?.map((num) => ({
    label: num,
    value: num,
  }));

  const sponsorshipAmount = useSelector(
    (state) => state.entitiesEdit.data.entityData?.programData?.sp_amount
  );

  const sponsorshipAmountOptions = sponsorshipAmount?.map((num) => ({
    label: num.symbol + num.amount,
    value: num.amount,
  }));

  const sponsorshipLevel = useSelector(
    (state) => state.entitiesEdit.data.entityData?.programData?.sp_num
  );

  const sponsorshipLevelOptions = sponsorshipLevel?.map((num) => ({
    label: num.symbol + num.amount,
    value: num.amount,
  }));

  const sponsorshipAmountCurrency = useSelector(
    (state) =>
      state.entitiesEdit.data.entityData?.programData?.sp_amount?.[0].symbol
  );

  const studentName = useSelector(
    (state) => state.entitiesEdit.data.entityData?.name
  );

  const entity_id = useSelector(
    (state) => state.entitiesEdit.data.entityData?.profile?.id
  );

  const profilePic = useSelector(
    (state) => state.entitiesEdit.data.entityData.profilePic
  );

  const onSendEmail = async () => {
    try {
      const emailData = await sendEmail({
        client_id: client_id,
        program_id: values.program_id,
        trigger: "profile_update",
        to: "donor",
        donor_id: sponsorId,
        entity_id,
      });
  
      if (emailData && emailData?.message === "Update Sent") {
        popNotification(emailData?.message,"success");
        onUpdateDialogClose()
      }
    } catch (error) {
      console.error("An error occurred while sending the email:", error);
      popNotification("Failed to send email", "danger");
    }
  };

  const popNotification = (keyword, type) => {
    toast.push(
      <Notification
        title={type === "success"? `Successful ${keyword}`:`Error ${keyword}` }
        type={type}
        duration={2500}
      >
        {keyword}
      </Notification>,
      {
        placement: "top-center",
      }
    );
  };

  const renderFields = () => {
    return sortedRecipientFields.map((field) => {
      switch (field.field_type) {
        case "hysText":
          return renderHysTextField(
            field,
            values,
            handleChange,
            errors,
            touched
          );
        case "hysTextarea":
          return renderHysTextareaField(field, errors, touched);
        case "hysDate":
        case "hysAge":
          return renderHysDateField(field, errors, touched);
        case "hysStatic":
          return renderHysStaticField(field);
        case "hysCheckbox":
          return renderHysCheckboxField(field, values, errors, touched);
        case "hysSelect":
          return renderHysSelectField(field, errors, touched);
        default:
          return null; // ignore fields of other types
      }
    });
  };

  const onUpdateDialogClose = () => {
    setUpdateDialogIsOpen(false);
  };

  const onUpdateDialogOpen = () => {
    setUpdateDialogIsOpen(true);
  };

  return (
    <AdaptableCard className="mb-4" divider isLastChild>
      <Dialog
        isOpen={updateDialogIsOpen}
        onClose={onUpdateDialogClose}
        onRequestClose={onUpdateDialogClose}
      >
        <h5 className="mb-4">Send Profile Update Notification</h5>
        <p>
          You are about to send profile update notifications to the current sponsor(s). If
          you are sure, please click 'Send,' or click 'Cancel' to close the
          dialog.
        </p>
        <div className="text-right mt-6">
          <Button
            className="ltr:mr-2 rtl:ml-2"
            variant="plain"
            onClick={onUpdateDialogClose}
          >
            Cancel
          </Button>
          <Button variant="solid" onClick={onSendEmail}>
            Send
          </Button>
        </div>
      </Dialog>
      <Dialog
        isOpen={messageManagerDialog}
        width={1000}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
      >
        <div className="flex flex-col h-full justify-between">
          <RecipientMessageNew 
            donor_id={sponsorId}
            donor_name={sponsorsName}
            studentName={studentName}
            programId={props.program_id}
            from_title={"entity"}
            entity_id={entity_id}
            onDialogClose={onDialogClose}
          />
        </div>
      </Dialog>
      <h5 className="mb-6">{studentName}'s Profile</h5>
      <div className="flex items-center justify-center">
      <img src={profilePic} alt="No Profile Pic" />
      </div>
      {sponsorsName !== undefined  && (
        <div>
          <>
            <p className="mb-3 mt-3 font-bold">Current Sponsors</p>
          </>
          <>
            {sponsors?.map((sponsor, index) => {
              const d = `donor-${sponsor.hysform_id}`;
              if (sponsor.amount === "") {
                return null;
              }
              const number = index + 1;
              if (permissions[`${d}`] === "1") {
                return (
                  <ActionLink
                    to={`/app/view_donor/${sponsor.id}`}
                    state={{ donor: true }}
                    key={`${sponsor.id}-${number}`}
                  >
                    <p className="mb-6">
                      {sponsor.name} {sponsorshipAmountCurrency}{" "}
                      {sponsor.amount} paid {sponsor.frequency}
                    </p>
                  </ActionLink>
                );
              } else if (sponsor.name === "No Name Found") {
                return (
                  <p className="mb-6" key={`${sponsor.id}-${number}`}>
                    {sponsor.name} (donor Deleted)
                  </p>
                );
              } else {
                return (
                  <p className="mb-6" key={`${sponsor.id}-${number}`}>
                    {sponsor.name}
                  </p>
                );
              }
            })}
          </>
        </div>
      )}
     
<div className="mb-3">
  {sponsors && sponsors?.length > 0 && !sponsors?.some(sponsor => sponsor.name === "No Name Found") && (
    <>
      <Button
        variant="solid"
        color="blue-600"
        size="sm"
        onClick={onUpdateDialogOpen}
        style={{ marginRight: 7 }}
        type="button"
      >
        Send Update Email
      </Button>

        <Button
          variant="solid"
          color="blue-600"
          size="sm"
          style={{ marginRight: 7 }}
          onClick={(e) => openDialog(e)}
        >
          Compose Message
        </Button>
     </>
  )}
</div>


      <div>
        {renderFields()}

        {programType === NUMBER_PROGRAM_TYPE && (
          <div>
            <div className="col-span-1">
              <FormItem label="Number of Sponsors required">
                <Field name="sp_num">
                  {({ field, form }) => (
                    <Select
                      field={field}
                      form={form}
                      options={sponsorshipNumberOptions}
                      value={sponsorshipNumberOptions?.filter(
                        (amount) => amount.value === values.sp_num
                      )}
                      onChange={(sp_num) =>
                        form.setFieldValue(field.name, sp_num.value)
                      }
                    />
                  )}
                </Field>
              </FormItem>
            </div>
          </div>
        )}

        {programType === CONTRIBUTION_PROGRAM_TYPE && (
          <div>
            <div className="col-span-1">
              <FormItem label="Contribution Level Required">
              <Field name="sp_num">
                  {({ field, form }) => (
                    <Select
                      field={field}
                      form={form}
                      options={sponsorshipLevelOptions}
                      value={sponsorshipLevelOptions?.filter(
                        (amount) => amount.value === values.sp_num
                      )}
                      onChange={(sp_num) =>
                        form.setFieldValue(field.name, sp_num.value)
                      }
                    />
                  )}
                </Field>
              </FormItem>
            </div>
          </div>
        )}

        {programType === CAMPAIGN_PROGRAM_TYPE && (
          <div>
            <div className="col-span-1">
              <FormItem label="Donation target">
                <Field
                  type="text"
                  autoComplete="off"
                  name="sp_amount"
                  component={Input}
                  value={donation_target}
                  disabled
                />
              </FormItem>
            </div>
          </div>
        )} 

        {programType !== CAMPAIGN_PROGRAM_TYPE && programType !==  CONTRIBUTION_PROGRAM_TYPE &&(
          <div>
            <div className="col-span-1">
              <FormItem label="Sponsorship Amount">
                <Field name="sp_amount">
                  {({ field, form }) => (
                    <Select
                      field={field}
                      form={form}
                      options={sponsorshipAmountOptions}
                      value={sponsorshipAmountOptions?.filter(
                        (amount) => amount.value === values.sp_amount
                      )}
                      onChange={(sp_amount) =>
                        form.setFieldValue(field.name, sp_amount.value)
                      }
                    />
                  )}
                </Field>
              </FormItem>
            </div>
          </div>
        )}
      </div>
    </AdaptableCard>
  );
};

export default RecipientFields;
