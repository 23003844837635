import React from "react";
import { Input, FormItem, Select } from "components/ui";
import { Field } from "formik";
import { RichTextEditor } from "components/shared";

const required = <span className="ml-1 opacity-60">(required)</span>;
const optional = <span className="ml-1 opacity-60">(optional)</span>;

const checkBoxRequired = <span className="ml-1 opacity-60">(required)</span>;
const checkBoxOptional = <span className="ml-1 opacity-60">(optional)</span>;

const selectOptionRequired = (
  <span className="ml-1 opacity-60">(required)</span>
);
const selectOptionOptional = (
  <span className="ml-1 opacity-60">(optional)</span>
);

// Helper function for rendering the hysText field
export const renderHysTextField = (
  field,
  values,
  handleChange,
  errors,
  touched,
  bulk
) => (
  <div className="col-span-3" key={field.field_key}>
    <FormItem
      label={field.field_label}
      extra={!bulk ? (field.required === 1 ? required : optional) : undefined}
      invalid={!!errors[field.field_key] && touched[field.field_key]}
      errorMessage={errors[field.field_key]}
    >
      <Field
        type="text"
        key={field.field_key}
        value={values[field.field_key]}
        autoComplete="off"
        name={field.field_key}
        component={Input}
        onChange={handleChange(field.field_key)}
      />
      {errors[field.field_key] && (
        <div className="text-red-500">{errors[field.field_key]}</div>
      )}
    </FormItem>
  </div>
);

// Helper function for rendering the hysDate and hysAge fields
export const renderHysDateField = (field, errors, touched, bulk) => (
  <div key={field.field_key}>
    <FormItem
      label={field.field_label}
      extra={!bulk ? (field.required === 1 ? required : optional) : undefined}
      invalid={!!errors[field.field_key] && touched[field.field_key]}
      errorMessage={errors[field.field_key]}
    >
      <Field
        type="date"
        autoComplete="off"
        name={field.field_key}
        component={Input}
      />
      {errors[field.field_key] && (
        <div className="text-red-500">{errors[field.field_key]}</div>
      )}
    </FormItem>
  </div>
);

// Helper function for rendering the hysTextarea field
export const renderHysTextareaField = (field, errors, touched, bulk) => (
  <div className="col-span-3" key={field.field_key}>
    <FormItem
      label={field.field_label}
      extra={!bulk ? (field.required === 1 ? required : optional) : undefined}
      invalid={!!errors[field.field_key] && touched[field.field_key]}
      errorMessage={errors[field.field_key]}
    >
      <Field name={field.field_key}>
        {({ field, form }) => (
          <>
            <RichTextEditor
              value={field.value}
              onChange={(val) => form.setFieldValue(field.name, val)}
            />
            {errors[field.field_key] && (
              <div className="text-red-500">{errors[field.field_key]}</div>
            )}
          </>
        )}
      </Field>
    </FormItem>
  </div>
);

// Helper function for rendering the hysSelect field
export const renderHysSelectField = (field, errors, touched, bulk) => {
  const { id, field_key, field_label, field_data } = field;

  const options = field_data
    .split(",")
    .map((option) => ({ label: option, value: option }));

  return (
    <div key={id}>
      <FormItem
        label={field_label}
        extra={!bulk ? (field.required === 1 ? selectOptionRequired : selectOptionOptional) : undefined}
        invalid={!!errors[field_key] && touched[field_key]}
        errorMessage={errors[field_key]}
      >
        <Field name={field_key}>
          {({ field, form }) => (
            <>
              <Select
                key={field_key}
                field={field}
                form={form}
                options={options}
                value={options.filter((fields) => fields.value === field.value)}
                onChange={(option) =>
                  form.setFieldValue(field.name, option.value)
                }
              />
              {errors[field_key] && (
                <div className="text-red-500">{errors[field_key]}</div>
              )}
            </>
          )}
        </Field>
      </FormItem>
    </div>
  );
};

// Helper function for rendering the hysStatic field
export const renderHysStaticField = (field) => (
  <div className="" key={field.field_key}>
    <FormItem label={field.field_label}>
      <p>{field.field_data}</p>
    </FormItem>
  </div>
);

// Helper function for rendering the hysCheckbox field
export const renderHysCheckboxField = (field, values, errors, touched, bulk) => {
  const { id, field_key, field_label, field_data } = field;

  const options = field_data
    .split(",")
    .map((option) => ({ label: option, value: option }));

  const emptyArray = [];

  return (
    <div key={id}>
      <FormItem
        label={field_label}
        extra={!bulk ? (field.required === 1 ? checkBoxRequired : checkBoxOptional) : undefined}
        invalid={!!errors[field_key] && touched[field_key]}
        errorMessage={errors[field_key]}
      >
        {options.map((option) => (
          <div key={option.value}>
            <Field
              type="checkbox"
              name={field_key}
              value={option.value}
              checked={(values[field_key] || emptyArray).includes(option.value)}
            />
            <label>{option.label}</label>
          </div>
        ))}
        {errors[field_key] && (
          <div className="text-red-500">{errors[field_key]}</div>
        )}
      </FormItem>
    </div>
  );
};
