import React, { useState, useEffect } from "react";
import RecipientMessageForm from "../RecipientMessageForm";
import { toast, Notification } from "components/ui";
import { useDispatch } from "react-redux";
import { Loading } from "components/shared";
import {
  apiSendRecipientMessage,
  apiDraftRecipientMessage,
  apiGetSendMessage,
} from "../../../services/ProgramsService";
import isEmpty from "lodash/isEmpty";
import { closeMessageManagerDialog } from "../ProgramsList/store/stateSlice";
import AWS from "aws-sdk";

const RecipientMessageNew = ({donor_id, donor_name, studentName, programId, fron_title, entity_id, onDialogClose}) => {
  const [mresponse, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const dispatch = useDispatch();

  const sendMessage = async (data) => {
    const response = await apiSendRecipientMessage(data);
    return response.data;
  };

  const handleMessage = async (data, apiFunction) => {
    const response = await apiFunction(data);
    return response.data;
  };

  const fetchData = async (data) => {
    const response = await apiGetSendMessage(data);
    setResponse(response.data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({
      donor_id: donor_id,
      from_title: "entity",
      entity_id: entity_id,
    });
  }, []);

  AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY_ID,
	});
	
	const bucketName = process.env.REACT_APP_DO_BUCKET;
	const region = process.env.REACT_APP_DO_REGION;

	// Create an instance of the S3 service
	const s3 = new AWS.S3({
	params: { Bucket: bucketName },
	region: region,
	});

  const handleFormSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    setBtnLoading(true)
      if (donor_id === undefined) {
        toast.push(
          <Notification title={"Error"} type="danger" duration={2500}>
            {`No donor is sponsoring this recipient`}
          </Notification>,
          {
            placement: "top-center",
          }
        );
        setSubmitting(false);
      }
      const { message, subject } = values;
      if (values.image) {
        try {
          const file = values.image
          let index = values.imgUrl.lastIndexOf("/")
          let result = values.imgUrl.substr(index + 1)
  
          const uploadParams = {
            Bucket: bucketName,
            Key: result + "." + file.name.split(".").pop(),
            Body: file,
            ContentType: file.type,
            ACL: "public-read"
          }
  
          s3.putObject(uploadParams, async (err, data) => {
            if (err) {
              console.log(`Error uploading file: ${err}`);
            } else {
              let success = null;
              if (values.draft === true) {
                success = await handleMessage(
                  {
                    entity_id: entity_id,
                    donor_id: donor_id,
                    from_title: "entity",
                    message: message,
                    subject: subject,
                    client_id: values.client_id,
                    fileName: result + "." + file.name.split(".").pop()
                  },
                  apiDraftRecipientMessage
                )
              } else {
                success = await sendMessage({
                  entity_id: entity_id,
                  donor_id: donor_id,
                  from_title: "entity",
                  message: message,
                  subject: subject,
                  client_id: values.client_id,
                  fileName: result + "." + file.name.split(".").pop()
                });
              }
              if (success) {
                setSubmitting(false);
                setBtnLoading(false)
                toast.push(
                  <Notification
                    title={"Successfully sent"}
                    type="success"
                    duration={2500}
                  >
                    Message successfully sent
                  </Notification>,
                  {
                    placement: "top-center",
                  }
                );
                dispatch(closeMessageManagerDialog())
              }
            }
          })
        } catch (error) {
          const onSendErrorMessage = error?.response?.data?.message;
          toast.push(
            <Notification title={"Error"} type="danger" duration={2500}>
              {onSendErrorMessage}
            </Notification>,
            {
              placement: "top-center",
            }
          );
        } finally {
          setSubmitting(false);
          setBtnLoading(false)
        }
      } else {
        let success = null;
        let draft = null;
        try {
          if (values.draft === true) {
            draft = true
            success = await handleMessage(
              {
                entity_id: entity_id,
                donor_id: donor_id,
                from_title: "entity",
                message: values.message,
                subject: values.subject,
                client_id: values.client_id,
              },
              apiDraftRecipientMessage
            );
          } else {
            success = await sendMessage({
              entity_id: entity_id,
              donor_id: donor_id,
              from_title: "entity",
              message: values.message,
              subject: values.subject,
              client_id: values.client_id,
            });
          }
          if (success) {
            setSubmitting(false);
            setBtnLoading(false)
            toast.push(
              <Notification
                title={draft ? "Draft saved" : "Successfully sent"}
                type="success"
                duration={2500}
              >
                {draft ? "Draft saved successfully" : "Message successfully sent"}
              </Notification>,
              {
                placement: "top-center",
              }
            );
            dispatch(closeMessageManagerDialog())
          }
        } catch (error) {
          const onSendErrorMessage = error?.response?.data?.message;
          toast.push(
            <Notification title={"Error"} type="danger" duration={2500}>
              {onSendErrorMessage}
            </Notification>,
            {
              placement: "top-center",
            }
          );

        } finally {
          setSubmitting(false);
          setBtnLoading(false)
        }
      }
  };

  const handleDiscard = () => {
    dispatch(closeMessageManagerDialog())
  };

  return (
    <>
      <Loading loading={loading}>
        {!isEmpty(mresponse) && (
          <>
            <RecipientMessageForm
              type="new"
              onFormSubmit={handleFormSubmit}
              onDiscard={handleDiscard}
              mresponse={mresponse}
              donor_id={donor_id}
              entity_id={entity_id}
              btnLoading={btnLoading}
            />
          </>
        )}
      </Loading>
    </>
  );
};

export default RecipientMessageNew;
